<template>
    <div class="dm_feed_page h-100">
        <!-- Navigation -->
        <header-vue></header-vue>

        <!-- Page Content -->
        <div class="container pb-5 pb-md-0" v-if="selected_user">
            <div class="row">
                <div class="col-12">
                    <div class="dm_profile_head_wrapper d-md-flex d-block justify-content-between">
                        <div class="dm_profile_head d-flex">
                            <div class="dm_avatar_wrapper">
                                <div class="dm_avatar">
                                    <img v-if="selected_user.photo" :src="API_URL+selected_user.photo" width="200">
                                    <img v-else src="https://via.placeholder.com/150" alt="" width="200">
                                </div>
                            </div>
                            <div class="dm_profile_details pl-4">
                                <h2 class="dog_name">{{ selected_user.dog_name }}</h2>
                                <h6 class="font-italic breed_name">{{ selected_user.breed_name }}</h6>
                                <h5 class="owner_name">{{ selected_user.username }}</h5>
                                <div class="d-flex">
                                    <div class="dm_dog_breed pr-3">
                                        <i class="fas fa-dog pr-1"></i>{{ selected_user.breed_name }}
                                    </div>
                                    <div v-if="selected_user.dob" class="dm_dog_dob pr-3">
                                        <i class="fas fa-birthday-cake pr-1"></i>{{ selected_user.dob }}
                                    </div>
                                    <div v-if="selected_user.gender" class="dm_dog_gender pr-3">
                                        <i class="fas fa-venus-mars pr-1"></i>{{ selected_user.gender }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="dm_profile_action_wrapper d-flex" v-if="selected_user.id != user.id">
                            <div @click="viewDetails(selected_user.id)"
                                class="dm_profile_action d-flex align-items-center justify-content-center mr-3">
                                <i class="fas fa-user"></i>
                            </div>
                            <div @click="createNewChannel()"
                                class="dm_profile_action d-flex align-items-center justify-content-center mr-3">
                                <i class="fas fa-envelope"></i>
                            </div>
                            <div class="dm_profile_action d-flex align-items-center justify-content-center">
                                <i class="fas fa-ellipsis-h"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row dm_profile_content_wrapper">

                <!-- Feeds -->
                <div class="col-12 col-lg-8">

                    <h5 class="dm_post_by_name text-uppercase">Post by {{ selected_user.dog_name }}</h5>
                    <!-- <div class="dm_dog_breed"><i class="fas fa-user"></i> {{ selected_user.username }}</div> -->
                    <!-- Blog Post -->
                    <div class="card mb-4 dm_feed_posts" v-for="(post, index) in posts" v-bind:key="post.id">
                        <div class="card-body p-3" v-if="posts && posts.length > 0">
                            <div class="row">
                                <div class="col-12">
                                    <div class="card-header border-0 p-0" v-if="post.user">
                                        <div class="row">
                                            <div class="col-10">
                                                <div class="d-flex">
                                                    <div class="">
                                                        <div class="dm_feed_avatar">
                                                            <img v-if="!post.user.photo || post.user.photo_id === null || post.user.photo.name == null"
                                                                src="https://via.placeholder.com/50">
                                                            <img v-else
                                                                :src="post.user.photo.name != null ? API_URL+post.user.photo.name : 'https://via.placeholder.com/50'">
                                                        </div>
                                                    </div>
                                                    <div class="pl-3">
                                                        <div class="dm_feed_username" style="cursor: pointer;"
                                                            @click="openProfile(post.user.id)">
                                                            <h5 class="m-0">{{ post.user.dog_name }}</h5>
                                                            <div class="dm_dog_breed"><i class="fas fa-user"></i>
                                                                {{ post.user.username }}</div>
                                                            <span>{{ frontEndDateFormat(post.created_at) }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-2 d-flex justify-content-end">
                                                <div class="dm_post_settings">
                                                    <a class="dropdown-toggle" href="#" role="button"
                                                        id="dropdownMenuLink" data-toggle="dropdown"
                                                        aria-haspopup="true" aria-expanded="false">
                                                        <i class="fas fa-ellipsis-h"></i>
                                                    </a>
                                                    <div class="dropdown-menu p-2" aria-labelledby="dropdownMenuLink"
                                                        v-if="selected_user.id == user.id">
                                                        <a class="dropdown-item p-2" href="#"
                                                            @click="updatePost(index)">
                                                            <i class="fas fa-edit pr-2"></i>Edit
                                                        </a>
                                                        <a class="dropdown-item p-2" href="#"
                                                            @click="deletePost(post.id, index)">
                                                            <i class="fas fa-trash pr-2"></i>Delete
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row py-2">
                                        <div class="col-12">
                                            <div class="dm_post_content">
                                                <p class="m-0 py-4">
                                                    {{ post.desc }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-6" v-for="image in post.images" v-bind:key="image.id">
                                            <div class="dm_post_content" style="margin-top: 20px">
                                                <img :src="image.image_path" width="100%">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row py-2">
                                        <div class="col-12">
                                            <div
                                                class="dm_post_info_wrapper d-block d-md-flex justify-content-between align-items-center">
                                                <div class="dm_post_likedby d-flex mb-2 mb-md-0">
                                                    <div class="dm_likes_avatar mr-2">
                                                        <span v-for="(likes, i) in post.likes" v-bind:key="likes.id"
                                                            :likes="likes">
                                                            <span v-if="post.likes[i].user && typeof post.likes[i].user.photo != 'undefined'">
                                                                <span
                                                                    v-if="!post.likes[i].user.photo || post.likes[i].user.photo_id === null || post.likes[i].user.photo.name == null">
                                                                    <img src="https://via.placeholder.com/50" width="15">
                                                                </span>
                                                                <img v-else
                                                                    :src="post.likes[i].user.photo.name != null ?API_URL+post.likes[i].user.photo.name : 'https://via.placeholder.com/50'"
                                                                    width="15">
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <span v-if="post.likes[0] && post.likes[0].user">
                                                        <p v-if="post.total_likes == 0" class="m-0">{{post.total_likes}}
                                                            liked this</p>
                                                        <p @click="viewLikesModal(post.id)" v-if="post.total_likes == 1"
                                                            class="m-0">{{post.likes[0].user.dog_name}} liked this</p>
                                                        <p @click="viewLikesModal(post.id)" v-if="post.total_likes == 2"
                                                            class="m-0">{{post.likes[0].user.dog_name}} &
                                                            {{post.likes[1].user.dog_name}} liked this</p>
                                                        <p @click="viewLikesModal(post.id)" v-else-if="post.total_likes > 2"
                                                            class="m-0">{{post.likes[0].user.dog_name}} ,
                                                        {{post.likes[1].user.dog_name}} & {{post.total_likes}} other
                                                        liked this</p>
                                                    </span>
                                                </div>
                                                <div class="dm_post_info d-flex">
                                                    <div v-if="post.is_liked == 0" @click="likePost(post.id)"
                                                        class="dm_paw mr-4 d-flex"><i class="fas fa-paw mr-2"></i>
                                                        <p class="m-0">Give a paw</p>
                                                    </div>
                                                    <div v-else @click="disLikePost(post.id)"
                                                        class="dm_paw mr-4 d-flex"><i style="color: #c8c7c7"
                                                            class="fas fa-paw mr-2"></i>
                                                        <p class="m-0">Erase a paw</p>
                                                    </div>
                                                    <div @click="viewCommentsModal(post.id)"
                                                        class="dm_bark mr-4 d-flex"><img
                                                            src="../../assets/img/bark@2x.png" height="16" class="mr-2">
                                                        <p class="m-0">{{post.total_comments}} Barks</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="dm_post_comments" v-if="post.total_comments > 0">
                                        <div class="col-sm-12 col-12 p-0 pb-4"
                                            v-for="comment in post.comments.slice().reverse()" v-bind:key="comment.id">
                                            <div class="dm_feed_username p-3">
                                                <h6 class="m-0 pb-1">{{ comment.user.dog_name }}</h6>
                                                <div class="dm_dog_breed pb-3"><i class="fas fa-user"></i>
                                                    {{ comment.user.username }}</div>
                                                <p class="mb-0 pb-1">{{ comment.desc }}</p>
                                                <p class="dm_feed_date mb-0">
                                                    {{ frontEndDateFormatTime(comment.created_at) }}</p>
                                            </div>
                                        </div>
                                        <hr>
                                    </div>
                                    <div class="card-footer border-0 p-0 d-flex justify-content-between">
                                        <div class="dm_feed_avatar">
                                            <img v-if="user.name" :src="API_URL+user.name">
                                            <img v-else src="https://via.placeholder.com/50">
                                        </div>
                                        <textarea @keydown.enter.shift.exact="newline" @keydown.enter.exact.prevent
                                            @keyup.enter.exact="createComment(post.id)"
                                            class="form-control border-0 shadow-none" :class="'comment_post_'+post.id"
                                            rows="1" :key-index="index" placeholder="Bark on the post..."></textarea>
                                        <a @click="createComment(post.id)">
                                            <i style="font-size: 25px;padding: 10px;" class="fas fa-paper-plane"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-4 dm_feed_posts" v-if="posts.length == 0">
                        <div class="card-body p-3">
                            <div class="row">
                                <div class="col-12">
                                    No Posts Found
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Pagination -->
                    <!-- <ul class="pagination justify-content-center mb-4">
                        <li class="page-item">
                            <a class="page-link" href="#">&larr; Older</a>
                        </li>
                        <li class="page-item disabled">
                            <a class="page-link" href="#">Newer &rarr;</a>
                        </li>
                    </ul> -->

                </div>

                <!-- Sidebar Widgets Column -->
                <div class="col-lg-4 d-none d-lg-block">
                    <div class="dm_sidebar">

                         <!-- Online friends Widget -->
                        <div class="card my-4 dm_card_shadow">
                            <div class="card-header text-uppercasebold">Mates ({{ user_list.length }})</div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col" v-for="list in user_list" v-bind:key="list.id" style="padding-bottom: 15px;">
                                        <a href="/chat">
                                            <div class="dm_side_avatar" :class="list.userList[0].is_online ? 'avatar-online' : 'avatar-offline'">
                                                <img v-if="list.userList[0].photo" :src="API_URL+list.userList[0].photo.name">
                                                <img v-else src="https://via.placeholder.com/150">
                                            </div>
                                            <div class="dm_side_username ml-2 d-flex justify-content-between" style="padding-top: 5px;color: #634E55">
                                                <p class="m-0">{{ list.userList[0].dog_name }}</p>
                                                <!-- <div class="dm_dog_breed"><i class="fas fa-user"></i> {{ list.userList[0].username }}</div> -->
                                                <!-- <span v-if="!list.userList[0].is_online" class="dm_online_status">{{ lastSeenDateFormatTime(list.userList[0].last_seen) }}</span> -->
                                            </div>
                                        </a>
                                    </div>
                                </div>

                                <!-- <div class="row align-items-center" v-for="list in user_list" v-bind:key="list.id" style="padding-bottom: 15px;">
                                    <div class="col-2">
                                        <div class="dm_side_avatar" :class="list.userList[0].is_online ? 'avatar-online' : 'avatar-offline'">
                                            <img v-if="list.userList[0].photo" :src="API_URL+list.userList[0].photo.name">
                                            <img v-else src="https://via.placeholder.com/150">
                                        </div>
                                    </div>
                                    <div class=" col-10">
                                        <div class="dm_side_username ml-2 d-flex justify-content-between">
                                            <h5 class="m-0">{{ list.userList[0].dog_name }} <br> <div class="dm_dog_breed"><i class="fas fa-user"></i> {{ list.userList[0].username }}</div></h5>
                                            <span v-if="!list.userList[0].is_online" class="dm_online_status">{{ lastSeenDateFormatTime(list.userList[0].last_seen) }}</span>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="row align-items-center" v-if="user_list && user_list.length == 0">
                                    <div class="dm_side_username ml-2 d-flex justify-content-between">
                                        No Mates found
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Profile info Widget -->
                        <div class="card my-4 dm_card_shadow">
                            <div class="card-header text-uppercase">Photos</div>
                            <div class="card-body p-3">
                                <div class="row align-items-center">
                                    <div class="col-4" v-for="images in selected_user.images" v-bind:key="images.id">
                                        <img :src="API_URL+images.name" width="70" height="70">
                                    </div>
                                    <div class="col-4" v-if="selected_user.images && selected_user.images.length == 0">
                                        No Record Found
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <!-- /.row -->

        </div>
        <!-- /.Modals -->
        <div class="modal likes-modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Total Post Likes</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="card-header border-0 p-0 dm_post_comments">
                            <div class="d-flex mb-3" v-for="like in likes" v-bind:key="like.id">
                                <div class="">
                                    <div class="dm_feed_avatar">
                                        <img v-if="like.user.photo" :src="API_URL+like.user.photo.name">
                                        <img v-else src="https://via.placeholder.com/50">
                                    </div>
                                </div>
                                <div class="pl-3">
                                    <div class="">
                                        <h5 class="m-0">{{ like.user.dog_name }}</h5>
                                        <div class="dm_dog_breed"><i class="fas fa-user"></i> {{ like.user.username }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                    </div>
                </div>
            </div>
        </div>

        <div class="modal comments-modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Total Post Barks</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="card-header border-0 p-0 dm_post_comments">
                            <div class="d-flex mb-3 dm_feed_username p-3" v-for="(like,i) in comments" v-bind:key="like.id">
                                <div class="col-1 p-0">
                                    <div class="dm_feed_avatar">
                                        <span
                                            v-if="!comments[i].user.photo || comments[i].user.photo_id === null || comments[i].user.photo.name == null">
                                            <img src="https://via.placeholder.com/50" width="15">
                                        </span>
                                        <img v-else
                                            :src="comments[i].user.photo.name != null ?API_URL+comments[i].user.photo.name : 'https://via.placeholder.com/50'"
                                            width="15">
                                    </div>
                                </div>
                                <div class="p-0 pl-4 col-11">
                                    <div class="">
                                        <h5 class="m-0 pb-1">{{ like.user.dog_name }}</h5>
                                        <div class="dm_dog_breed pb-3"><i class="fas fa-user"></i> {{ like.user.username }}</div>
                                        <p class="mb-0 pb-1">{{ like.desc }}</p>
                                        <p class="dm_feed_date mb-0">{{ frontEndDateFormatTime(like.created_at) }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="card-footer border-0 p-0 d-flex justify-content-between" style="width: 100%;">
                            <div class="dm_feed_avatar">
                                <img v-if="user.name" :src="API_URL+user.name">
                                <img v-else src="https://via.placeholder.com/50">
                            </div>
                            <textarea @keydown.enter.shift.exact="newline" :class="'modal_comment_post_'+post_id"
                                @keydown.enter.exact.prevent @keyup.enter.exact="createComment(post_id)"
                                class="form-control border-0 shadow-none" rows="1"
                                placeholder="Bark on the post..."></textarea>
                            <a @click="createComment(post_id)"><i style="font-size: 25px;padding: 10px;" class="fas fa-paper-plane"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal post-modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Edit Post</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="card mb-4 dm_new_post dm_card_shadow">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group d-flex">
                                            <div class="">
                                                <div class="dm_feed_avatar">
                                                    <img v-if="user.name" :src="API_URL+user.name">
                                                    <img v-else src="https://via.placeholder.com/150">
                                                </div>
                                            </div>
                                            <div class="pl-3">
                                                <textarea class="form-control border-0 shadow-none input-description" rows="5"
                                                    placeholder="Share what you are thinking here..."></textarea>
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="card-footer p-0 d-flex justify-content-between">
                                            <input onchange="window.updateFile(this);" type="file" name="image" class="input-file" style="display: none;" id="upload-file" accept="image/*" multiple>
                                            <button @click="triggerFileInput()" class="dm_btn_med_outline py-2 px-5"><i
                                                    class="fas fa-camera mr-2"></i>Photo</button>
                                            <button @click="updatePostData()" class="dm_btn_dark_solid py-2 px-5">Update Post</button>
                                        </div>
                                        <hr>
                                        <div class="card-footer p-0 d-flex justify-content-between" v-if="selected_post">
                                            <div id="post_image_status"></div>
                                            <div id="post_photos" class="row">
                                                <div v-for="post in selected_post.images" v-bind:key="post.id" class="col-md-3 col-sm-3 col-xs-3" :class="'post_image_'+post.id">
                                                    <img :src="post.image_path" class="img-thumbnail"><a @click="deleteImage(post.id)"><i class="fas fa-trash"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                    </div>
                </div>
            </div>
        </div>
        <!-- /.container -->
        <footer-vue></footer-vue>
    </div>
</template>
<script>
    import Headervue from '../partials/header-vue'
    import Footervue from '../partials/footer-vue'
    import {
        API_PROFILE_IMAGE_URL
    } from '../../constants'

    export default {
        name: "profile",
        data: () => ({
            user: [],
            user_list:[],
            selected_user: [],
            selected_post: [],
            posts: [],
            breeds: [],
            likes: [],
            comments: [],
            search_text: '',
            breed_id: '',
            state: '',
            errors: {},
            image: '',
            title: '',
            desc: '',
            post_id: 0,
            API_URL: API_PROFILE_IMAGE_URL,
            files: '',
            count: 1,
            selected_comment: 0,
        }),
        watch: {
            '$route.params.id': function () {
                this.getPostsListById().then((data) => {
                    console.log(data);
                })
            }
        },
        components: {
            'header-vue': Headervue,
            'footer-vue': Footervue
        },
        sockets: {
            post: function (data) {
                if (data.user.id == localStorage.getItem('user_id')) {
                    this.posts.unshift(data);
                }
            },
            'post-likes': function (data) {
                var _this = this;
                for (var i = _this.posts.length - 1; i >= 0; i--) {
                    if (_this.posts[i].id == data.post_id) {
                        _this.post_id = data.post_id;
                        _this.index = i;
                        _this.updatePostLikesById();
                    }
                }
            },
            'post-comment': function (data) {
                var _this = this;
                for (var i = _this.posts.length - 1; i >= 0; i--) {
                    if (_this.posts[i].id == data.comment.post_id) {
                        _this.post_id = data.comment.post_id;
                        _this.index = i;
                        _this.updatePostCommentById();
                    }
                }
                if ((_this.comments[0] && _this.comments[0].post_id == data.comment.post_id) || (_this
                        .selected_comment == data.comment.post_id)) {
                    _this.comments.push(data.comment);
                }
            }
        },
        methods: {
            likePost: function (post_id) {
                this.$socket.emit('user:like-post', {
                    post_id: post_id
                }, function (data) {
                    if (data) {
                        window.notify(true, 'Post Liked successfully');
                    }
                });
            },
            disLikePost: function (post_id) {
                this.$socket.emit('user:dislike-post', {
                    post_id: post_id
                }, function (data) {
                    if (data) {
                        window.notify(true, 'Post DisLiked successfully');
                    }
                });
            },
            filterPost: function () {
                this.getPostsList();
            },
            triggerFileInput: function () {
                window.$('.input-file').trigger('click');
            },
            createPostData: function () {
                this.title = window.$(".input-description").val();
                this.desc = window.$(".input-description").val();
                this.files = window.$("input[type=file]")[0].files;
                // if (window.$(".input-description").val() == '') {
                //     window.notify(false, 'Please add description');
                //     return false;
                // }
                this.createPost();
            },
            viewLikesModal: function (post_id) {
                this.post_id = post_id;
                this.getLikeList();
            },
            viewCommentsModal: function (post_id) {
                if (this.comments.length == 0) {
                    this.selected_comment = post_id;
                }
                this.post_id = post_id;
                this.getCommentList();
            },
            updatePostData: function () {
                this.title = window.$(".post-modal .input-description").val();
                this.desc = window.$(".post-modal .input-description").val();
                this.files = window.$(".post-modal input[type=file]")[0].files;
                if (window.$(".post-modal .emojionearea-editor").text() == '' && window.$(".post-modal input[type=file]")[0].files && window.$(".post-modal input[type=file]")[0].files.length == 0) {
                    window.notify(false, 'Please add description');
                    return false;
                }
                this.updateCreatedPost();
            },
            updatePost: function (index) {
                this.selected_post = this.posts[index];
                this.index = index;
                window.$('.post-modal').modal('show');
                window.$(".post-modal .emojionearea-editor").text(this.selected_post.desc);
            },
            viewDetails: function (id) {
                localStorage.setItem('details_user_id', id);
                this.$router.push({ path: 'view-details' })
            },
            frontEndDateFormat: function (dates) {
                let date = window.moment(dates);
                return window.moment(date, 'YYYY-MM-DD').format('MMMM DD, YYYY');
            },
            frontEndDateFormatTime: function (dates) {
                let date = window.moment(dates);
                return window.moment(date, 'YYYY-MM-DD').format('MMMM DD, YYYY hh:mm A');
            },
            lastSeenDateFormatTime: function(dates) {
                var exp = window.moment();
                var now = window.moment(dates);

                let days = exp.diff(now, 'days');
                let hours = exp.diff(now, 'hours') - (days * 24);
                let minutes = exp.diff(now, 'minutes') - ((days * 1440) + (hours * 24) * 60);

                if(dates != null){
                    if(days > 0){
                        return days+' Days ago'
                    }else if(hours > 0){
                        return hours+' Hours ago';
                    }else{
                        return minutes+' Minutes ago';
                    }
                }else{
                    return '';
                }
            },
            async updatePostById() {
                var _this = this;
                let form = {
                    post_id: this.post_id
                };
                let responce = await this.$store.dispatch("getPostsByID", form);
                _this.posts[_this.index] = responce.data.data;
                _this.$forceUpdate();
                _this.post_id = 0;

            },
            async updatePostCommentById() {
                var _this = this;
                let form = {
                    post_id: this.post_id
                };
                let responce = await this.$store.dispatch("getPostsByID",form);
                _this.posts[_this.index].total_comments = responce.data.data.total_comments;
                _this.posts[_this.index].comments = responce.data.data.comments;
                _this.$forceUpdate();
                _this.post_id = 0;

            },
            async updatePostLikesById() {
                var _this = this;
                let form = {
                    post_id: this.post_id
                };
                let responce = await this.$store.dispatch("getPostsByID",form);
                _this.posts[_this.index].likes = responce.data.data.likes;
                _this.posts[_this.index].total_likes = responce.data.data.total_likes;
                _this.posts[_this.index].is_liked = responce.data.data.is_liked;
                _this.$forceUpdate();
                _this.post_id = 0;

            },
            async updateCreatedPost() {
                var _this = this;
                let form = {
                    image: this.files,
                    title: this.title,
                    desc: this.desc,
                    post_id: this.selected_post.id
                };
                let responce = await this.$store.dispatch("updatePost", form);
                if (responce.data.status) {
                    window.notify(responce.data.status, 'Post updated successfully');
                    window.$('.post-modal .emojionearea-editor').text('');
                    window.$('.post-modal .input-description').val('');
                    window.$(".post-modal input[type=file]").val('');
                    window.$(".post-moda #post_photos").html('');
                    window.$('.post-modal').modal('hide');
                    _this.post_id = _this.selected_post.id;
                    _this.selected_post = [];
                    _this.updatePostById();
                }
            },
            async getPostsListById() {
                let form = {
                    post_id: this.$route.params.id
                };
                let responce = await this.$store.dispatch("getPostsByID", form);
                this.posts[0] = responce.data.data;
                this.$forceUpdate();
            },
            async createComment(post_id) {
                let form = {
                    post_id: post_id,
                    desc: typeof window.$('.modal_comment_post_'+post_id).val() != 'undefined' ? window.$(
                        '.modal_comment_post_' + post_id).val() : window.$('.comment_post_' + post_id).val()
                };
                let responce = await this.$store.dispatch("createComment", form);
                if (responce.data.status) {
                    window.notify(responce.data.status, 'Comment added successfully');
                }
                window.$('.comment_post_' + post_id).val('');
                window.$('.modal_comment_post_' + post_id).val('');
            },
            async getPostsList() {
                let form = {
                    search_text: this.search_text,
                    breed: this.breed_id,
                    state: this.state,
                    post_id: this.post_id,
                    user_id: localStorage.getItem('user_id'),
                };
                let responce = await this.$store.dispatch("getPostsList", form);
                this.posts = responce.data.data;
            },
            async appendPostsList() {
                var _this = this;
                let form = {
                    search_text: this.search_text,
                    breed: this.breed_id,
                    state: this.state,
                    post_id: this.post_id
                };
                let responce = await this.$store.dispatch("getPostsList", form);
                if (responce.data.data && responce.data.data.length > 0) {
                    for (var i = 0; i < responce.data.data.length; i++) {
                        _this.posts.push(responce.data.data[i]);
                    }
                    window.setTimeout(function () {
                        _this.count = 1;
                    }, 5000);
                }
            },
            async getLikeList() {
                let form = {
                    post_id: this.post_id
                };
                let responce = await this.$store.dispatch("getLikeList", form);
                this.likes = responce.data.data.reverse();
                window.$('.likes-modal').modal('show');
            },
            async getCommentList() {
                let form = {
                    post_id: this.post_id
                };
                let responce = await this.$store.dispatch("getCommentList", form);
                this.comments = responce.data.data.reverse();
                window.$('.comments-modal').modal('show');
            },
            async getBreedList() {
                let responce = await this.$store.dispatch("getBreedList");
                if (responce.status) {
                    this.breeds = responce.data.data;
                }
            },
            async getSelectedUser() {
                let form = {
                    user_id: localStorage.getItem('user_id')
                };
                let responce = await this.$store.dispatch("getSelectedUser", form);
                if (responce.status) {
                    this.selected_user = responce.data.data;
                }
            },
            async getAuthUser() {
                let responce = await this.$store.dispatch("getAuthUser");
                if (responce.status) {
                    this.user = responce.data.data;
                }
            },
            async sendRequest() {
                let form = {
                    user_id: this.selected_user.id
                };
                let responce = await this.$store.dispatch("sendRequest",form);
                if (responce.data.status) {
                    window.notify(responce.data.status, 'Request sent successfully');
                    this.$router.push({ path: 'chat' })
                }
            },
            async createNewChannel() {
                let form = {
                    user_id: this.selected_user.id
                };
                let responce = await this.$store.dispatch("createNewChannel",form);
                if (responce.data.status) {
                    window.notify(responce.data.status, 'Request sent successfully');
                    this.$router.push({ path: 'chat' })
                }
            },
            async deletePost(post_id,index) {
                var _this = this;
                let form = {
                    post_id: post_id
                };
                let responce = await this.$store.dispatch("deletePost",form);
                if (responce.data.status) {
                    _this.posts.splice(index,1);
                    window.notify(responce.data.status, 'Post deleted successfully');
                }
            },
            async deleteImage(image_id) {
                var _this = this;
                let form = {
                    post_image_id: image_id
                };
                let responce = await this.$store.dispatch("deletePostImage",form);
                if (responce.status == 200) {
                    window.$('.post_image_'+image_id).remove();
                    _this.post_id = _this.selected_post.id;
                    _this.updatePostById();
                    window.notify(responce.data.status, 'Image deleted successfully');
                }
            },
            async getfirendsList() {
                let responce = await this.$store.dispatch("chatRecents");
                if (responce.status) {
                    this.user_list = responce.data.data;
                }
            },
        },
        created() {
            var _this = this;
            this.getAuthUser();
            if(typeof this.$route.params.id != "undefined" && this.$route.params.id != ''){
                this.getPostsListById();
            }else{
                this.getPostsList();
            }
            this.getBreedList();
            this.getSelectedUser();
            this.getfirendsList();
            window.setTimeout(function () {
                var lastScrollTop = 0;
                window.$(window).scroll(function () {
                    var st = window.$(this).scrollTop();
                    if (st > lastScrollTop) {
                        if (_this.count == 1) {
                            if (_this.posts && _this.posts.length >= 15) {
                                let buffer = 400 // # of pixels from bottom of scroll to fire your function. Can be 0
                                if (window.$(".dm_feed_page").prop('scrollHeight') - window.$(window)
                                    .scrollTop() <= window.$(window).height() + buffer) {
                                    _this.post_id = _this.posts[_this.posts.length - 1].id;
                                    _this.count = 0
                                    _this.appendPostsList();
                                }
                            }
                        }

                    }
                });

                window.$(".input-description").emojioneArea({
                    pickerPosition: "bottom",
                    tonesStyle: "bullet",
                });
            }, 500);
        }
    };
</script>
